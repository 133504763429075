var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "leaflet-details mx-0 py-0 grey lighten-1",
      attrs: { fluid: "" }
    },
    [
      _vm.leaflet
        ? _c("div", { staticClass: "white py-5 px-2 d-flex align-center" }, [
            _c("div", { staticClass: "flex-grow-1" }, [
              _c("div", { staticClass: "text-body-0 font-weight-semibold" }, [
                _vm._v(_vm._s(_vm.leaflet.name))
              ]),
              _c("div", [
                _vm._v(
                  " Dal " +
                    _vm._s(_vm.$dayjs(_vm.leaflet.fromDate).format("DD MMMM")) +
                    " al " +
                    _vm._s(
                      _vm.$dayjs(_vm.leaflet.toDate).format("DD MMMM YYYY")
                    ) +
                    " "
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "flex-shrink-0" },
              [
                _c(
                  "v-btn",
                  { attrs: { icon: "" }, on: { click: _vm.toggleZoom } },
                  [
                    _c("v-icon", { attrs: { color: "default" } }, [
                      _vm._v(_vm._s(_vm.pageZoom ? "$zoomOut" : "$zoom"))
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  { attrs: { icon: "" }, on: { click: _vm.toogleFullscreen } },
                  [
                    _c("v-icon", { attrs: { color: "default" } }, [
                      _vm._v("$fullscreen")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.downloadLeaflet(_vm.leaflet)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "default" } }, [
                      _vm._v("$download")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.share()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "default" } }, [
                      _vm._v("$share")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { ref: "leafletViewerWraper" }, [
        _vm.leaflet
          ? _c(
              "div",
              {
                staticClass: "leafletViewer",
                class: {
                  fullscreen: _vm.leafletFullscreen,
                  iosFullscreen: _vm.isIOSFullscreen
                }
              },
              [
                _vm.leafletFullscreen || _vm.isIOSFullscreen
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "exit-fullsreen",
                        attrs: { fab: "", color: "white" },
                        on: { click: _vm.toogleFullscreen }
                      },
                      [_c("v-icon", [_vm._v("$close")])],
                      1
                    )
                  : _vm._e(),
                _c(
                  "swiper",
                  {
                    ref: "swiper",
                    attrs: {
                      options: _vm.swiperOptions,
                      "slides-per-view": "auto"
                    },
                    on: {
                      doubleTap: _vm.handleZoom,
                      zoomChange: _vm.handleZoom
                    }
                  },
                  _vm._l(_vm.leaflet.leafletPage, function(page) {
                    return _c("swiper-slide", { key: page.leafletPageId }, [
                      _c("div", { staticClass: "leaflet-page-container" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "imgage-container swiper-zoom-container py-2 py-md-6",
                            style: {
                              maxHeight: _vm.swiperHeight,
                              height: _vm.fullscreen ? _vm.swiperHeight : "auto"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "image",
                              attrs: {
                                src: `${_vm.leaflet.baseLocation}${page.imageLocation}`,
                                alt: `Volantino pagina ${page.pageNumber}`
                              }
                            })
                          ]
                        )
                      ])
                    ])
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }